import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Nosotros from "../views/Nosotros.vue";
import Contactenos from "../views/Contactenos.vue";
import ListadoProductos from "../views/ListadoProductos.vue";
import InvitacionBoda from "../views/InvitacionBoda.vue";
import MilyJhonatan from "../views/MilyJhonatan.vue";
import InvitacionCumpleanos from "../views/InvitacionCumpleanos.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  { path: "/", name: "Home", component: Home, meta: { layout: "DefaultLayout" } },
  { path: "/nosotros", name: "Nosotros", component: Nosotros, meta: { layout: "DefaultLayout" } },
  { path: "/contactenos", name: "Contactenos", component: Contactenos, meta: { layout: "DefaultLayout" } },
  { path: "/productos", name: "ListadoProductos", component: ListadoProductos, meta: { layout: "DefaultLayout" } },
  {
    path: "/ohbaby/mily-y-jhonatan",
    name: "MilyJhonatan", component: MilyJhonatan,
    meta: {
      layout: "OhBabyLayout",
      title: "Revelacion de genero Mily & Jhonatan",
      description: "Te invitamos a descubrir si nuestra dulce espera se pinta de rosa o azul.",
      image: "/assets/mily-y-jhonatan/ninia-o-ninio.jpg",
    },
  },
  { path: "/producto/invitacion-boda", name: "InvitacionBoda", component: InvitacionBoda, meta: { layout: "ProductLayout" } },
  { path: "/producto/invitacion-cumpleanos", name: "InvitacionCumpleanos", component: InvitacionCumpleanos, meta: { layout: "ProductLayout" } },
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound, meta: { layout: "DefaultLayout" } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Cambiar dinámicamente el título y meta descripción
router.beforeEach((to, from, next) => {
  const defaultTitle = "Suspiros Digitales";
  const defaultDescription = "Amor en cada píxel, creando invitaciones digitales únicas.";
  const domain = "https://suspirosdigitales.com"; // Dominio base de tu sitio
  const defaultImage = `${domain}/path/to/default-image.jpg`; // Ahora usamos esta variable
  const defaultUrl = domain;

  // Establece el título
  document.title = to.meta.title || defaultTitle;

  // Meta descripción
  const descriptionMetaTag = document.querySelector('meta[name="description"]');
  if (descriptionMetaTag) {
    descriptionMetaTag.setAttribute("content", to.meta.description || defaultDescription);
  } else {
    const meta = document.createElement("meta");
    meta.name = "description";
    meta.content = to.meta.description || defaultDescription;
    document.head.appendChild(meta);
  }

  // Open Graph Meta Tags
  const ogProperties = {
    "og:title": to.meta.title || defaultTitle,
    "og:description": to.meta.description || defaultDescription,
    "og:image": to.meta.image ? `${domain}${to.meta.image}` : defaultImage,
    "og:image:secure_url": to.meta.image ? `${domain}${to.meta.image}` : defaultImage,
    "og:image:width": "1200",
    "og:image:height": "628",
    "og:image:type": "image/jpeg",
    "og:url": defaultUrl + to.fullPath,
    "og:type": "website",
  };

  // Twitter Cards
  const twitterProperties = {
    "twitter:card": "summary_large_image",
    "twitter:title": to.meta.title || defaultTitle,
    "twitter:description": to.meta.description || defaultDescription,
    "twitter:image": to.meta.image ? `${domain}${to.meta.image}` : defaultImage,
  };

  // Actualiza las etiquetas Open Graph dinámicamente
  Object.keys(ogProperties).forEach((property) => {
    let tag = document.querySelector(`meta[property="${property}"]`);
    if (tag) {
      tag.setAttribute("content", ogProperties[property]);
    } else {
      const meta = document.createElement("meta");
      meta.setAttribute("property", property);
      meta.setAttribute("content", ogProperties[property]);
      document.head.appendChild(meta);
    }
  });

  // Actualiza las etiquetas Twitter Cards dinámicamente
  Object.keys(twitterProperties).forEach((name) => {
    let tag = document.querySelector(`meta[name="${name}"]`);
    if (tag) {
      tag.setAttribute("content", twitterProperties[name]);
    } else {
      const meta = document.createElement("meta");
      meta.setAttribute("name", name);
      meta.setAttribute("content", twitterProperties[name]);
      document.head.appendChild(meta);
    }
  });

  next();
});

export default router;
