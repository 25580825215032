<template>
  <div class="text-center mt-5">
    <h1>Error 404</h1>
    <p>La página que estás buscando no existe.</p>
    <router-link to="/" class="btn btn-primary">Volver al inicio</router-link>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
h1 {
  font-size: 3rem;
  color: #dc3545; /* Color rojo de error */
}

p {
  font-size: 1.25rem;
  color: #6c757d; /* Color gris de texto */
}
</style>
