<template>
  <div class="container-fluid">
    <div class="title mb-4">
      <div class="row">
        <div class="col-3">
          <img class="img-fluid teddy" src="@/assets/images/oh-baby/teddy-boy.svg" alt="Suspiros Digitales" />
        </div>
        <div class="col-6">
          <h1>
            <span class="question">?</span>
            <span class="color-pink">Niña</span> <small class="color-generic">o</small> <span class="color-blue">Niño</span>
          </h1>
        </div>
        <div class="col-3">
          <img class="img-fluid teddy" src="@/assets/images/oh-baby/teddy-girl.svg" alt="Suspiros Digitales" />
        </div>
      </div>
    </div>
    <div class="player mb-4 text-center">
      <audio ref="audioPlayer" :src="audioSrc" loop></audio>
      <div class="player-toggle mb-2">
        <button @click="togglePlayPause" class="btn btn-outline-primary">
          <svg viewBox="0 0 85 60" xmlns="http://www.w3.org/2000/svg"><g fill="#fa8a9a"><path d="m38.11.04c2.22-.35 4.38 1.44 4.52 3.69v52.73c-.4 2.59-3.05 4.27-5.53 3.21-6.6-5.18-12.49-11.28-18.92-16.7-6.08-.84-15.79 2.4-17.74-5.89-.59-2.54-.58-11.22-.07-13.81 1.73-8.71 11.56-5.42 17.81-6.25l17.34-15.52c.78-.57 1.61-1.3 2.59-1.46zm.27 55.25v-50.58l-16.78 15.11c-.61.35-1.08 1-1.69 1.3-1.86.91-9.52.03-12.22.31-2.07.21-3.08 1.23-3.29 3.32-.26 2.59-.2 7.43-.01 10.08.17 2.45 1.02 3.53 3.51 3.75 3.48.31 7.66-.42 11.06-.01.47.06.84.21 1.23.47l18.2 16.25z"/><path d="m70.13.1c1.48-.06 4.3 3.03 5.32 4.18 13.3 15.11 12.97 40.71-2.66 54.12-1.13.97-3.05 2.47-4.23.62-1.36-2.12 1.81-4 3.06-5.44 12.4-14.23 12.4-32.92 0-47.16-.73-.84-2.89-2.57-3.24-3.38-.55-1.24.39-2.89 1.75-2.94z"/><path d="m61.63 9.74c1.96-.08 5.61 4.63 6.66 6.26 6.27 9.79 5.25 22.63-2.5 31.25-.89.99-2.79 3.05-4.15 3s-2.3-1.7-1.75-2.94c.39-.88 3.19-3.27 4.07-4.47 5.63-7.73 5.63-17.96 0-25.68-.85-1.17-3.83-3.78-4.12-4.64-.42-1.22.5-2.73 1.8-2.78z"/><path d="m52.99 19.32c2.32-.28 5.02 3.98 5.73 5.91 1.43 3.86 1.14 8.02-.98 11.56-.94 1.56-4.34 5.81-6.08 3.06-1.31-2.07.74-3.09 1.79-4.39 2.71-3.36 2.59-7.73-.1-11.03-.54-.66-1.79-1.5-1.98-2.29-.29-1.25.24-2.65 1.6-2.81z"/></g></svg> {{ isPlaying ? 'Pausa' : 'Play' }}
        </button>
      </div>
      <div class="player-bar px-5">
        <input type="range" v-model="currentTime" :max="duration" class="form-range" @input="seek" />
      </div>
      <div class="time"><span>{{ formatTime(currentTime) }}</span></div>
    </div>
    <div class="summary">
      <div class="mb-3">Con muchísima emoción</div>
      <div class="parents mb-3"><span class="color-pink">Mily</span> <small>&</small> <span class="color-blue">Jhonatan</span></div>
      <div class="mb-3">
        Te invitamos a descubrir si nuestra dulce espera se pinta de <b class="color-pink">rosa</b> o <b class="color-blue">azul</b>.<br>
        Tu compañía hará este momento aún más especial el día.
      </div>
    </div>
    <div class="date mb-4">
      <div class="">
        <span class="line-right">Lunes</span> <span class="numbre">23</span> <span class="line-left">Diciembre</span>
      </div>
      <div class="hour mb-2">Del 2024 a las 11:00 AM</div>
      <div class="place">Lugar: Jinua, Paria, Huaraz</div>
    </div>
    <div class="counter mb-4">
      <h5 class="counter-title text-center">Falta</h5>
      <div class="row text-center">
        <div class="col-3 color-blue">
          <span class="d-block display-6">{{ days }}</span>
          <span class="span">Días</span>
        </div>
        <div class="col-3 color-pink">
          <span class="d-block display-6">{{ hours }}</span>
          <span class="span">Horas</span>
        </div>
        <div class="col-3 color-blue">
          <span class="d-block display-6">{{ minutes }}</span>
          <span class="span">Minutos</span>
        </div>
        <div class="col-3 color-pink">
          <span class="d-block display-6">{{ seconds }}</span>
          <span class="span">Segundos</span>
        </div>
      </div>
    </div>
    <div class="text-center mb-4">
      <a class="btn btn-outline-primary map-btn" href="https://maps.app.goo.gl/p5sP3xxL6bQQdMht6" target="_blank">
        <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg"><g><path d="m29.7 30.5c6.6 0 12.1-5.4 12-12 0-6.6-5.4-12-12-12s-12 5.4-12 12 5.4 12 12 12zm0-21.6c5.3 0 9.6 4.3 9.5 9.5 0 5.2-4.3 9.5-9.5 9.5s-9.5-4.2-9.5-9.5 4.2-9.5 9.5-9.5z"/><path d="m38.29 41.57c2.64-3.79 4.88-7.27 6.51-10.37 2.7-4.9 4-8.9 4-11.8 0-5.2-2-10-5.6-13.7s-8.4-5.7-13.5-5.7c-10.5-0-19.1 8.7-19.1 19.4 0 3.5.9 7 2.7 10v.1l8.14 12.16c-10.29.91-21.44 3.7-21.44 9.04 0 2.8 3.2 5.2 9.3 6.9 5.6 1.5 12.9 2.4 20.7 2.4s15.1-.9 20.7-2.5c6.1-1.7 9.3-4.1 9.3-6.9 0-5.31-11.2-8.1-21.71-9.03zm-25.29-22.07c0-9.3 7.5-16.9 16.6-16.9s16.6 7.6 16.6 16.9c0 4.46-3.93 12.36-11.11 22.24-.12.11-.22.24-.29.4-2.26 3.04-4.32 5.59-5.5 7.07l-4.6-6.94c-.04-.1-.1-.17-.17-.25l-9.14-13.8c-1.5-2.7-2.3-5.7-2.4-8.7zm37 35.6c-5.3 1.5-12.4 2.3-20 2.3s-14.7-.8-20-2.3c-5.5-1.5-7.5-3.4-7.5-4.5 0-2.07 6.54-5.6 20.54-6.56l5.26 7.86c.2.4.5.6 1 .6.3 0 .7-.2 1-.5 0 0 2.87-3.43 6.29-8.05 14.21.97 20.91 4.48 20.91 6.65 0 1.1-2 2.9-7.5 4.5z"/><path d="m23 51.4-7.5-3.8c-.6-.3-1.4 0-1.7.6s0 1.4.6 1.7l7.5 3.8c.2.1.4.1.6.1.5 0 .9-.3 1.1-.7.3-.6 0-1.4-.6-1.7z"/><path d="m43.3 47.6-7.5 3.8c-.7.3-.9 1.1-.6 1.7.2.4.6.7 1.1.7.1 0 .3-.1.6-.1l7.5-3.8c.7-.3.9-1.1.6-1.7-.3-.7-1.1-.9-1.7-.6z"/></g></svg>
        Como llegar
      </a>
    </div>
    <div class="by mb-4">
      <div class="row">
        <div class="col-3">
          <img class="img-fluid teddy" src="@/assets/images/oh-baby/teddy-girl2.svg" alt="Suspiros Digitales" />
        </div>
        <div class="col-6">
          <div class="text-center mb-3">¡Te esperamos con mucha emocion y alegría!</div>
          <div><img class="logo img-fluid" src="@/assets/images/logo/primary.svg" alt="Suspiros Digitales" /></div>
        </div>
        <div class="col-3">
          <img class="img-fluid teddy" src="@/assets/images/oh-baby/teddy-boy2.svg" alt="Suspiros Digitales" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MilyJhonatan",
  data() {
    return {
      audioSrc: '/assets/mily-y-jhonatan/jm-como-seras.mp3',
      isPlaying: false,
        currentTime: 0,
        duration: 0,

        targetDate: new Date('2024-12-23T00:00:00'),
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      };
    },
    methods: {
      togglePlayPause() {
        const audio = this.$refs.audioPlayer;

        if (this.isPlaying) {
          audio.pause();
        } else {
          audio.play();
        }
        this.isPlaying = !this.isPlaying;
      },
      formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
        return `${minutes}:${secs}`;
      },
      seek(event) {
        const audio = this.$refs.audioPlayer;
        audio.currentTime = event.target.value;
      },
      updateCountdown() {
        const now = new Date();
        const difference = this.targetDate - now;

        if (difference > 0) {
          this.days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
          this.hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
          this.minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
          this.seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');
        } else {
          this.days = '00';
          this.hours = '00';
          this.minutes = '00';
          this.seconds = '00';
          clearInterval(this.countdownInterval);
        }
      },
    },
    mounted() {
      const audio = this.$refs.audioPlayer;

      audio.addEventListener('loadedmetadata', () => {
        this.duration = Math.floor(audio.duration);
      });

      audio.addEventListener('timeupdate', () => {
        this.currentTime = Math.floor(audio.currentTime);
      });

      audio.loop = true;

      this.updateCountdown();
      this.countdownInterval = setInterval(this.updateCountdown, 1000);
    },
    beforeUnmount() {
      clearInterval(this.countdownInterval);
    },
  };
</script>