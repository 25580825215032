<template>
  <div class="default-layout">
    <div class="text-center mt-5">
      <img class="img-fluid" src="@/assets/images/logo.svg" alt="Suspiros Digitales" />
    </div>
  </div>
</template>
<script>
export default {
  name: "DefaultLayout",
};
</script>
<style lang="scss" src="@/assets/scss/default-layout.scss"></style>