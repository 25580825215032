<template>
  <div>
    <h1>Nosotros</h1>
    <p>Somos Suspiros Digitales, una marca dedicada a hacer tus momentos especiales inolvidables.</p>
  </div>
</template>
<script>
export default {
  name: "AboutUsView", // Cambiar el nombre del componente
};
</script>
