<template>
  <div>
    <h1>Contáctenos</h1>
    <form>
      <label for="nombre">Nombre:</label>
      <input type="text" id="nombre" />
      <label for="email">Correo:</label>
      <input type="email" id="email" />
      <button type="submit">Enviar</button>
    </form>
  </div>
</template>
<script>
export default {
  name: "ContactUsView", // Cambiar el nombre del componente
};
</script>
