<template>
  <div>
    <h1>Listado de Productos</h1>
    <ul>
      <li v-for="producto in productos" :key="producto.id">
        <router-link :to="`/producto/${producto.slug}`">{{ producto.nombre }}</router-link>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "ListadoProductos",
  data() {
    return {
      productos: [
        { id: 1, nombre: "Invitación Boda", slug: "invitacion-boda" },
        { id: 2, nombre: "Invitación Cumpleaños", slug: "invitacion-cumpleanos" },
        { id: 3, nombre: "Invitación Baby Shower", slug: "invitacion-baby-shower" },
      ],
    };
  },
};
</script>