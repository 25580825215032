import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import ProductLayout from "@/layouts/ProductLayout.vue";
import OhBabyLayout from "@/layouts/OhBabyLayout.vue";

const app = createApp(App);

document.addEventListener('contextmenu', (event) => {
  event.preventDefault();
});

app.component("DefaultLayout", DefaultLayout);
app.component("ProductLayout", ProductLayout);
app.component("OhBabyLayout", OhBabyLayout);

app.use(router);

app.mount("#app");