<template>
    <div class="main-banner">
      <h2>Invitación Boda</h2>
      <p>Una elegante invitación para bodas.</p>
    </div>
</template>

<script>
export default {
  name: "InvitacionBoda",
};
</script>