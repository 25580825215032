<template>
    <section class="main-banner bg-secondary pt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-8">
            <div class="px-5 text-center">
              <h2 class="text-primary">El comienzo ideal para tu evento está en la invitación</h2>
              <p>Hoy es el día para tu invitación perfecta!</p>
              <a href="#" class="btn btn-outline-primary">Ver ejemplos</a>
            </div>

          </div>
          <div class="col-4">
            <img class="img-fluid" src="@/assets/images/banner.png" alt="Suspiros Digitales">
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white py-5">
      <div class="container">

        <div class="row align-items-center">
          <div class="col-4">
            <img class="img-fluid" src="@/assets/images/banner.png" alt="Suspiros Digitales">
          </div>
          <div class="col-8">
            <h2>Invitación digital interactiva</h2>
            <p>Tu invitación web de boda digital diseñada y desarrolla perfectamente a tu medida.</p>
          </div>
        </div>

        

      </div>
    </section>
    <section class="bg-gray py-5">
      <div class="container">
        <div class="">Banner</div>
      </div>
    </section>
    <section class="py-5">
      <div class="container">
        <h1 class="text-primary">Bienvenido a Suspiros Digitales</h1>
        <p>Descubre nuestras invitaciones digitales.</p>
      </div>
    </section>
</template>
<script>
export default {
  name: "HomeView",
};
</script>