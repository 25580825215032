<template>
  <div class="product-detail">
    <h2>Invitación Cumpleaños</h2>
    <p>Una invitación divertida para cumpleaños.</p>
  </div>
</template>

<script>

export default {
  name: "InvitacionCumpleanos",
};
</script>
